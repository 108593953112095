/**
 * Execute a callback once, before the page is hidden.
 */
export function beforeHide(callback) {
	document.addEventListener(
		"visibilitychange",
		() => document.visibilityState === "hidden" && callback(),
	);
	document.addEventListener("beforeunload", callback, { once: true }); // Legacy
	document.addEventListener("pagehide", callback, { once: true }); // Safari
}
