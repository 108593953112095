{
  "name": "@fiverr-private/relay-sdk",
  "version": "1.6.1",
  "description": "Send things to the relay API endpoint",
  "homepage": "https://github.com/fiverr/cloudflare-edge-workers/tree/master/packages/relay-sdk#readme",
  "author": "Fiverr Team",
  "keywords": [],
  "license": "UNLICENSED",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/fiverr/cloudflare-edge-workers.git"
  },
  "files": [
    "dist"
  ],
  "main": "./dist/index.js",
  "type": "module",
  "types": "./dist/index.d.ts",
  "exports": {
    ".": {
      "import": "./dist/index.js",
      "require": "./dist/index.cjs"
    },
    "./index.d.ts": "./dist/index.d.ts",
    "./package.json": "./package.json",
    "./dist/legacy": "./dist/legacy/index.js"
  },
  "sideEffects": false,
  "scripts": {
    "build": "./scripts/build.sh",
    "prepublishOnly": "npm run build",
    "publish-package": "npx published"
  },
  "devDependencies": {
    "@swc/core": "^1.5.7",
    "@types/web": "^0.0.139",
    "tsup": "^8.0.1"
  },
  "dependencies": {
    "@recursive/assign": "^3.0.1"
  },
  "publishConfig": {
    "tag": "latest"
  }
}
