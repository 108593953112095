const { DEFAULT_LOCALE } = require('../lib/constants');

/**
 * The default context to use when context does not exists over the current runtime
 * @type {FiverrContext}
 */
exports.DEFAULT_CONTEXT = {
    locale: DEFAULT_LOCALE,
    computedLocale: DEFAULT_LOCALE,
    userId: 0,
    country: '',
    countryCode: '',
    pageCtxId: '',
    isPro: false,
    abTests: {},
    userAgent: '',
    knownCrawler: false,
    csrfToken: '',
    browserLanguage: '',
    currency: '',
    pathParameters: {},
    queryParameters: {},
    userGuid: '',
    url: '',
    ip: '',
    referrer: '',
    organizationId: '',
    experience: {
        isBuyer: false,
        isSeller: false,
        isBusiness: false,
        value: '',
    },
    isMobile: false,
    isTouch: false,
    isIos: false,
};
