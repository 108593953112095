import { stringify } from "../stringify";
import type { Payload } from "../types";

export class Sender {
	#endpoint: string;
	constructor({ endpoint }: { endpoint: string }) {
		this.#endpoint = endpoint;
	}
	// This interface returns a promise so that we can change it to a fetch request or a websocket request in the future if desired
	// It should, however, be a fire-and-forget operation
	async send(payload: Payload): Promise<boolean> {
		const body = stringify(payload);
		if (body === undefined) return false;
		if (body === "[object Object]") {
			setTimeout(() => {
				const error = new SyntaxError(
					[
						"Failed to stringify payload",
						`(${payload})`,
						typeof payload,
						typeof payload === "object" ? Object.keys(payload) : "",
					]
						.filter(Boolean)
						.join(" "),
				);
				Object.assign(error, {
					code: "PAYLOAD_STRINGIFY",
					url: location.href,
				});
				throw error;
			});
			return false;
		}
		return globalThis?.navigator?.sendBeacon?.(this.#endpoint, body);
	}
}
