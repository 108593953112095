import { ListenerMode } from '@fiverr-private/obs';
import { getMetaTagValue } from '@fiverr-private/layout_lib/getMetaTagValue';
import {
    META_TAG_NAME_CTX_ID,
    META_TAG_NAME_PAGE_NAME,
    META_TAG_NAME_LOCALE,
    META_TAG_NAME_CURRENCY,
    META_TAG_NAME_USER_ID,
    META_TAG_NAME_U_GUID,
} from '@fiverr-private/layout_constants';
import { listener as monicoreListener } from '@fiverr-private/monicore';

/**
 * Initialize Monicore listener using existing DOM metatags.
 */

const mode = getMetaTagValue('app_environment') as ListenerMode;
const facility = getMetaTagValue('fiverrmeta:facility');
const host = getMetaTagValue('browser_relay_service_api');

monicoreListener({
    host,
    facility,
    mode,
}).setEnricher(() => ({
    page: {
        ctx: getMetaTagValue(META_TAG_NAME_CTX_ID),
        name: getMetaTagValue(META_TAG_NAME_PAGE_NAME),
        url: document.location.href,
        locale: getMetaTagValue(META_TAG_NAME_LOCALE),
        currency: getMetaTagValue(META_TAG_NAME_CURRENCY),
    },
    user: {
        id: getMetaTagValue(META_TAG_NAME_USER_ID),
        uid: getMetaTagValue(META_TAG_NAME_U_GUID),
    },
}));
