import _ from 'lodash';
import { logger } from '@fiverr-private/obs';
import { DEFAULT_CONTEXT } from '../constants';
import { CONTEXT_DOES_NOT_EXIST } from './constants';

/**
 * Returns the current available context for browser run time environment.
 * @returns {FiverrContext}
 */
const getContext = () => {
    const context = _.get(window, 'initialData.FiverrContext');

    if (!context) {
        logger.error({ message: CONTEXT_DOES_NOT_EXIST });
        return DEFAULT_CONTEXT;
    }

    return context;
};

export default getContext;
