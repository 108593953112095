import { isNil } from 'lodash';
import axios from 'axios';
import { socketHandler } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import * as stats from '@fiverr-private/layout_lib/stats';
import { METRICS } from './metric';
import { DEFAULT_ACTION, DEFAULT_CONTROLLER, REALTIME_CONNECTION_EVENT, SOCKET_CONFIG_URL } from './constants';

/**
 * Prepares the configuration to match the form expected by the server.
 * @param config
 */
const createPayload = (config: SocketConfiguration): ConnectSocketPayload => ({
    event: REALTIME_CONNECTION_EVENT,
    payload: {
        username: config.username,
        token: config.token,
        controller: DEFAULT_CONTROLLER,
        action: DEFAULT_ACTION,
        subscription: config.subscription,
    },
});

/**
 * Fetches the configurations required to establishing the socket connection.
 */
const fetchConfig = async (): Promise<SocketConfiguration> => {
    try {
        const { data } = await axios.post(SOCKET_CONFIG_URL);
        stats.count(METRICS.FETCH_CONFIGURATION_SUCCESS);

        return data;
    } catch (error) {
        stats.count(METRICS.FETCH_CONFIGURATION_FAILURE);
        throw error;
    }
};

/**
 * Initializes the global socketHandler used
 * by Fit.
 */
export const init = async () => {
    try {
        const { userId } = getContext();

        if (isNil(userId)) {
            stats.count(METRICS.CONNECT_SKIPPED);
            return;
        }

        const config = await fetchConfig();

        socketHandler.connect(config.url, createPayload(config));

        stats.count(METRICS.CONNECT_SUCCESS);
    } catch (error: unknown) {
        stats.count(METRICS.CONNECT_FAILURE);
    }
};

init();
